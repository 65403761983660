@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
body {margin:0; padding:0; font-size: 14px; font-family: 'Roboto',  'Noto Sans KR', sans-serif;min-width: 1000px;}
img, table {border: 0; border-image-width:0}
html, body {overflow: hidden;}

table.body {
  width: 100%;
  height: 100%;
  border: 0;
  margin: -2px
}

li{ list-style:none;}



body {
  min-width: 1000px
}

.navi {
  color: #fff;
  height: 40px;
  line-height: 38px;
  background: #354751;
  padding-left:7px;
  font-size:20px;
}

.navi .part {
  
  padding-right:10px;
  height: 40px;
  text-align: center;
  float: left
}
/* padding-left:20px; */
.navi .btn_zoom {
  float: right;
  width: 42px;
  height: 35px;
  padding-right: 5px
}

.navi .remain {
  float: right;
  width:300px;
  height: 40px;
  text-align: right;
  color: #fff;
  padding-right:18px;
}

.navi b {
  font-weight: 900;
  font-size:20px;
}

.body {
  position: relative;
}

.frame_box {padding-right:189px; padding-left:100px;}

.navi_panel {
  width: 101px;
  background: url(../images/question/navi_bg.jpg) right repeat-y;
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0px;
  min-height: 1000px;
  border-right: 1px solid #adaeb3;
  font-size:18px;
  text-align:center;
  color:#fff;
  line-height:40px;

}
.navi_panel .navi_part{
	background:#112e3d;
	color:#1ea6ef;
	height:40px;
}

.navi_panel .navi_task{
	background:#f5a700;
	margin:10px 5px 5px 5px;
	height:30px;
  line-height: 30px;

}

.navi_panel ul.navi_num{
	margin:5px 6px 5px 6px;
	overflow:hidden;
	display:block;

}

.navi_panel ul.navi_num li{
	float:left;
	width:43px;
	height:28px;
	list-style:none;
	margin-right:1px;
	margin-bottom:1px; 
	line-height: 25px;
	background:#fff;
	color:#000;

}
.navi_panel ul.navi_num li.finish{
	background:#00aeeb;
	color:#fff;

}


.control_panel {
  width: 250px;
  background: #dcdcdc;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 40px;
  min-height: 1000px;
  border-left: 1px solid #adaeb3
}


.contents { position:relative;
  padding: 15px 15px 35px 15px; min-height: 800px;width:80%
}



.header_box_test {
		border:1px solid  #8ec63f ;
	border-radius:30px; 	
	padding:10px 10px 10px 30px;
	font-size: 18px;
	line-height:22px;
	font-weight: 400;
	line-height: 30px;
	background:#ffffff;
	margin-bottom:20px;	
}

.header_box_test strong {	
	color:#8ec63f; 
	font-size: 22px;
	line-height:22px;
	padding-right:20px;
	margin-right:20px;
	border-right:1px solid #8ec63f;
	}
	
	
.header_box_mock {
		border:1px solid  #1ea6ef ;
	border-radius:30px; 	
	padding:10px 10px 10px 30px;
	font-size: 18px;
	line-height:22px;
	font-weight: 400;
	line-height: 30px;
	background:#ffffff;
	margin-bottom:20px;	
}

.header_box_mock strong {	
	color:#1ea6ef; 
	font-size: 22px;
	line-height:22px;
	padding-right:20px;
	margin-right:20px;
	border-right:1px solid #1ea6ef;
	}
	
	.header_box_study {
		border:1px solid  #3ec2c6 ;
	border-radius:30px; 	
	padding:10px 10px 10px 30px;
	font-size: 18px;
	line-height:22px;
	font-weight: 400;
	line-height: 30px;
	background:#ffffff;
	margin-bottom:20px;	
}

.header_box_study strong {	
	color:#3ec2c6; 
	font-size: 22px;
	line-height:22px;
	padding-right:20px;
	margin-right:20px;
	border-right:1px solid #3ec2c6;
	}	
	

.contents .text_box {
	border:1px solid  #e4e4e4 ;
	border-radius:10px; 	
	padding:10px;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	background:#ffffff;
	
}

p
{
	margin:0px;
	line-height: 166%;
}

.contents .img_box {
  text-align: center;
  margin-top: 30px;
  max-width:1000px
}

.headphone_img { text-align: center; margin-top: 150px}

.contents .img_box img {
  display: block;
}

img {
  border: 0;
  margin: 0;
  padding: 0
}

.clear {
  clear: both
}

.volume {
  width: 188px;
  height: 126px;
  border-bottom: 1px solid #b5bdc7;
  position: relative;
  background: url(../images/question/volume_bg.jpg)
}

.title { color:#354751; font-size:17px; font-weight:bold;}
.line, .title {
  padding-bottom: 6px
}

.line {
  border-top: 1px solid #fff;
  border-bottom: 1PX solid #b5bdc7;
  padding: 20px 12px
}

.progress_box {
  background: url(../images/question/progress_bg.gif);
  width: 180px;
  height: 13px;
  float: left;
  margin-top:5px;
}

.progress {
  background: url(../images/question/progress.gif);
  width: 180px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: 0
}

.progress_cover {
  background: url(../images/question/progress_cover.gif);
  width: 180px;
  height: 13px
}

/* .volume_cover {
  background: url(../../images/question/volume_cover.gif);
  width: 180px;
  height: 23px
} */

.time_box {
	position:relative;
	top:-10px;	
  background:#ffa300;
  border-radius:30px;
  width: 39px;
  height: 39px;
  text-align: center;
  line-height: 39px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  float: right;
 
}



.control_panel .disabled {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  zoom: 1;
  opacity: 0.5
}

.control_panel .hide {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  zoom: 1;
  opacity: 0
}

.studying_view{
	background:#b1f0a3;
	border:#26a20a 1px solid;}
	
.listening_view{
	/* background:#afe9ff; */
	/* border:#0f6789 1px solid; */
}
	
.response_view{
	/* background:#ffdeb9; */
	/* border:#e7860e 1px solid; */
}
	
.msg_warning{ text-align:center; margin:10px auto;}
	
	
.control_panel .disabled .progress,
.control_panel .disabled .time_box span {
  display: none
}

.control_panel .btn_box {
	text-align:center;
  margin: 10px 0px 0 0px;
}

.btn_box {
  cursor: pointer;
  cursor: hand
}

.bottom_line {
  border-bottom: 1px solid #fff;
  margin: 20px -12px -22px -12px
}

.recoding span {
  display: none
}


.recoding .active {
  position: relative;  height: 37px;
}

  .recoding .active img {     display: block;
    position: absolute;
    left: 12px;
    top: 5px;
  }
  .recoding .active .text {
    color:#fff;
    font-size: 18px;
    font-weight: 500;
    height: 37px; line-height: 36px  ;text-align: left;
;padding-left: 50px
}
.disabled .recoding .active {
  display: none
}
.disabled .recoding span {
  display: block;
  font-size: 18px;
  font-weight: 500;
  height: 37px;
  line-height: 36px;
  color: #fff;
  text-align: left;
  padding-left: 50px
}

.recoding_bg .alert {
  display: block;
  font-size: 12px;
  color: #fff100;
  text-align: center; padding-top:3px
}

.disabled .recoding_bg .alert {
  display: none
}

.btn_box img {
  display: block
}

.recoding_bg {
    background-color: #354751;
  border-radius:30px;
  width: 160px;
  height: 49px;
  margin: 10px auto
}

.emergency {
  position: absolute;
  left: 1092px;
  right: 0;
  bottom: 48px;
  width: 100px;
  background: url(../images/question/panel_bg.jpg) right repeat-y;
  text-align: right;
  height: auto;
  padding: 0 0 10px 0;z-index: 2
}

.emergency .em_line {
  width: 192px;
  border-top: 1px solid 1PX solid #b5bdc7;
  border-bottom: 1px solid #fff;
  height: 0px;
  margin-bottom: 10px
}

.emergency .btn_box img {
  margin: auto
}

.volume_btn {
  position: absolute;
  right: 5px;
  top: 8px;
  cursor: pointer;
  cursor: hand
}

.volume .equalizer {
  background: #646464;
  position: absolute;
  width: 151px;
  height: 48px;
  right: 19px;
  top: 61px;
}

.volume .volume_bar {
  background: url(../images/question/volume_control_bg.gif);
  width: 58px;
  height: 126px;
  position: absolute;
  z-index: 3;
  left: 82px;
  top: -10px;
  display: none
}

.volume .active {
  display: block
}

.volume .volume_bar .progress_box {
  margin: 8px 0 0 12px;
}

.volume .volume_cursor {
  position: absolute;
  left: 12px;
  bottom: 13px;
  width: 119px;
}

.volume .volume_cursor img {
  position: absolute;
      bottom: 100px;
      margin-bottom: -5px;
}
.queston_Frame { width:100%; height:100%; min-height: 942px}


.descript_box{ position:relative;
	line-height:150%;
	min-height:5px !important;
	border:1px solid #dadede;
	border-radius:10px;
	
	padding:10px;
	font-size: 18px;
	line-height: 30px;
	background:#ffffff;
	margin-bottom:120px;
}
.qust_box{position:relative; left:50%; margin-left:-390px;width:720px;}


.headset_box { float:left; width:330px; height:330px; margin-right:50px; border:#3e5360 2px solid;}
.mic_box {float:left; width:330px; height:330px;border:#3e5360 2px solid;}
.qust_box .box_top {height:275px;}
.qust_box .box_bottom {position:relative;height:55px; line-height:400%; background:#3e5360; }
.qust_box .headset_box  p{ display:block;height:55px; width:100%; background:url(../images/question/msg_say.gif) #3e5360 no-repeat 50%;}
.qust_box .box_bottom span{display: block; width:35px; height:35px;position: absolute; left: 35px;top: 14px;background:url(../images/question/recoding.gif) #3e5360 no-repeat}

.headset{ background:url(../images/question/img_headset.png) no-repeat 50% 50%;}
.mic{background:url(../images/question/img_mic.png) no-repeat 50% 50%;}

.progress_box2 {
	position:absolute;
	margin-left:80px;
  background: url(../images/question/progress_bg2.gif);
  width: 200px;
  height: 13px;
  margin-top:22px;
}

.progress2 {
  background: url(../images/question/progress2.gif);
  width: 200px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: 0
}

.progress_cover2 {
  background: url(../images/question/progress_cover2.png);
  width: 200px;
  height: 13px
}


.qust_box .disabled, 
.qust_box .disabled .box_bottom  {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  zoom: 1;
  opacity: 0.5
}

.qust_box .disabled .box_bottom p{background:url(../images/question/msg_say_dis.png) #3e5360 no-repeat 50%;}
.qust_box .disabled .box_bottom .img{display: block; width:35px; height:35px;position: absolute;  left: 35px;top: 14px; background:url(../images/question/recoding_dis.gif) #3e5360 no-repeat}


.headset_box .disabled {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  zoom: 1;
  opacity: 0.5
}
.headset_box .disabled p{background:url(../images/question/msg_say_dis.png) #3e5360 no-repeat 50%;}



.btn_sayagain {
	   background:url(../images/question/ico_say.gif) 5% center no-repeat; 
width:100%;
  background-color: #354751;
  border-radius:30px;
  border: none;
  color: white;
  padding: 15px 32px 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-weight:bold;
  
}


.btn_choice {
 background:url(../images/question/ico_hear.gif) 5% center no-repeat; 
width:100%;
  background-color: #354751;
  border-radius:30px;
  border: none;
  color: white;
  padding: 15px 32px 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-weight:bold;
  margin-top:10px;
  
}


.btn_record {
  width:100%;
   background:url(../images/question/ico_record.gif) 5% center no-repeat; 
  background-color: #354751;
  border-radius:30px;
  border: none;
  color: white;
  padding: 15px 32px 15px 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-weight:bold;  
}

.btn_record_on {
   width:100%;
   background:url(../images/question/ico_record_on.gif) 5% center no-repeat; 
  background-color: #354751;
  border-radius:30px;
  border: none;
  color: white;
  padding: 15px 32px 15px 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-weight:bold;  
}









.btn_answer{	
  background:#ffffff;
  border:1px solid  #ffa300;
  border-radius:30px;
  width: 50px;
  height: 50px;
  line-height: 39px;
   text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #ffa300;   
	}
	
.on{	
  background:#ffa300;
  border:1px solid  #ffa300;
  border-radius:30px;
  width: 50px;
  height: 50px;
  line-height: 39px;
   text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #fff;  
    cursor: pointer; 
	}
	
	
	.btn_response{	
  background:#fff;
  border:1px solid  #c1c7cb;
  border-radius:30px;
  width: 80px;
  height: 39px;
  line-height: 39px;
   text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #5d686f;   
	}
	
	
	
	
.record_on{	
  background:#f50606;
  border:1px solid  #f50606;
  border-radius:30px;
  width: 80px;
  height: 39px;
  line-height: 39px;
   text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #fff;   
	}
		
	.play_on{	
  background:#1658c5;
  border:1px solid  #1658c5;
  border-radius:30px;
  width: 80px;
  height: 39px;
  line-height: 39px;
   text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #fff;   
	}




.btn_next{
 width:100%;	
  background-color: #8ec63f;
  border: none;
  color: white;
  padding: 25px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  cursor: pointer;
  font-weight:bold;
 
}

.btn_next span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn_next span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.btn_next:hover span {
  padding-right: 25px;
}

.btn_next:hover span:after {
  opacity: 1;
  right: 0;
}



/* Finish page */

.Last_box { text-align:center; color:#FFF; padding:10%;}
.Last_box h1 { font-size:60px;}
.Last_box span { background:rgb(0, 0, 0, 0.2); padding:8px 25px; border-radius:25px; font-size:18px;}
.btn_area { margin-top:50px;}

.btn_retest {
 background:url(../images/question/ico_retest.png) 20px center no-repeat; 
width:200px;
border:2px solid #ffffff;
  border-radius:30px;
  color: white;
  padding: 15px 32px 15px 50px;
 text-align: right;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight:bold;

  margin:10px;
  
}
.btn_close {
 background:url(../images/question/ico_close.png)  20px center no-repeat; 
width:200px;
border:2px solid #ffffff;
  border-radius:30px;
  color: white;
  padding: 15px 32px 15px 50px;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight:bold;

 margin:10px;
  
}

.btn_start {
	background:none;
width:200px;
border:2px solid #ffffff;
  border-radius:30px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight:bold;
 margin:10px;
  
}


/*학습모듈 */

.study_box { margin:30px; border:1px solid #eeeeee; padding:30px; border-radius:30px;  text-align:center  }


.control_state_box {  padding:40px 30px 10px 30px; } 

.state_bar_bg { height:6px; background:#f1f1f1; width:100%; margin-bottom:10px;}
.state_bar {  height:6px; background:#3ec2c6; margin-bottom:10px; position:relative; }
.state_bar span { text-align:right; font-size:30px;  display:block; line-height:70px;font-weight:bold;  }


.state_on {background:url(../images/question/state_on.png) no-repeat; width:30px; height:30px; z-index:100000;  position:absolute; margin-top:-10px;}
.time_s { float:left; font-size:20px; position:absolute; top:10px; left:30px; color:#3ec2c6; font-weight:bold}  
.time_f {float:right; font-size:20px; position:absolute; top:10px; right:30px; font-weight:bold; color:#9e9e9e} 

.control_audio_box { width:300px; margin:0 auto; text-align:left; padding:30px 30px; border:2px solid #3ec2c6;   border-radius:50px; margin-top:-70px; background: #FFF; } 

.control_audio_left {width:40%; float:left;  margin-top:-20px; }
.control_audio_left img { margin:auto 5px; vertical-align:middle}
.control_audio_right{width:60%; float:right;  margin-top:0px;}

.audio_bar_bg { height:4px; background:#666; width:100%;}
.audio_bar {  height:4px; background:#3ec2c6;}

.control_bottom_box { background-color:#3ec2c6; padding:10px 30px; height:70px; margin-top:30px; } 
.control_left  { float:left;}
.control_right {float:right; }
.control_right img {vertical-align:middle;}

.btn_outline {	
background:none;
border:2px solid #ffffff;
  border-radius:30px;
  color: white;
  padding: 15px 30px;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  line-height:20px;
  cursor: pointer;
  font-weight:bold;
 margin:5px;
  
}

.btn_record_module {	
background:red;
border:2px solid red;
  border-radius:30px;
  color: white;
  padding: 15px 30px;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  line-height:20px;
  cursor: pointer;
  font-weight:bold;
 margin:5px;
  
}

.btn_rplay_module {	
background:#0f6c6f;
border:2px solid #0f6c6f;
  border-radius:30px;
  color: white;
  padding: 15px 30px;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  line-height:20px;
  cursor: pointer;
  font-weight:bold;
 margin:5px;
  
}

.time_state {	
background:#2fa9ac;
border:2px solid #2fa9ac;
  border-radius:30px;
  color: white;
  padding: 15px 30px;;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  line-height:20px;
  font-weight:bold;
 margin:5px;
  
}