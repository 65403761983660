@charset "utf-8";



/* ====================== */
/* Reset   */
/* ====================== */
hr {display: none;}
ul, ol {list-style: none;}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote, th, 
td, legend {margin:0;padding:0;}


/* body, * {color:#666666;font-family:Dotum, Arial, sans-serif;} */
/* html, body {height:100%;font-size:12px; margin:0; color:#444;} */

/*인용문*/
blockquote:before, blockquote:after, q:before, q:after { content: ""; }
blockquote, q { quotes: "" ""; }

a {text-decoration:none;color:#666666;}

address, caption, cite, code, dfn, em, th, var {font-style:normal;font-weight:normal;}
strong {font-style:normal;}

fieldset,img,abbr,acronym {border:0 none;}

fieldset legend {display:none;}

label,input,select,textarea,img {vertical-align:middle;}

table { border-collapse:collapse; border-spacing:0px;}
caption { position:absolute; visibility:hidden; width:0; height:0; overflow:hidden; font:0; display:none;}
th {font-weight:bold;}

h1,h2,h3,h4,h5,h6 {font-size:100%;font-weight:normal;}

/* ====================== */
/* Basic Layout   */
/* ====================== */
html>/**/body {overflow-x:auto;overflow-y:scroll;}
body {position:relative;width:100%;}

/* ====================== */
/* Form   */
/* ====================== */
input[type="text"],input[type="date"], input[type="password"], select {padding:0px;margin:0;height:20px;border:1px solid #d7d7d7;font-family:'맑은 고딕', '나눔고딕', 'Nanum_R',"돋움", dotum, arial, sans-serif;font-size:12px;vertical-align:middle;}
input[type="TEXTAREA"] {padding:0px;margin:0;border:1px solid #d7d7d7;font-family:'맑은 고딕', '나눔고딕', 'Nanum_R',"돋움", dotum, arial, sans-serif;font-size:12px;vertical-align:middle;}
.input {padding:0px;margin:0;border:1px solid #d7d7d7;font-family:'맑은 고딕', '나눔고딕', 'Nanum_R',"돋움", dotum, arial, sans-serif;font-size:12px;vertical-align:middle;}
.type_ip{padding:0px;margin:0;height:20px;border:1px solid #d7d7d7;font-family:'맑은 고딕', '나눔고딕', 'Nanum_R',"돋움", dotum, arial, sans-serif;font-size:12px;vertical-align:middle;}


/* ====================== */
/* Default   */
/* ====================== */
/* color */
.color_bl {color:#000 !important;}
.color_gr {color:#555 !important;}
.color_wh {color:#fff !important;}
.color_re {color:#ff0000 !important;}
.color_blu {color:#0266cc !important;}
.color_sky {color:#4fa6db !important;}
.color_nor {color:#888 !important;}/* body기본색상 */

.comment_red {color:#d60000 !important;}

/* text */
.comment_blue{font-size:11px; color:#3754d8}

.txt_blue {font-size:12px; color:#104674;}
.txt_red {font-size:12px; color:#ff0000;}

.subject {display:inline-block;overflow:hidden;/*width*/text-overflow:ellipsis;white-space:nowrap;-webkit-line-clamp:1;-webkit-orient:vertical;}

.left {float:left !important;}
.right {float:right !important;}
.cb {clear:both !important;}
.cl {clear:left !important;}
.zbox {*zoom:1;}
.zbox:after {content:"";display:block;clear:both;}
.zbox>li {float:left;}

.ls-1 {letter-spacing:-1px !important;}
.ws-1 {word-spacing:-1px !important;}

.tleft {text-align:left !important;}
.tright {text-align:right !important;}
.tcenter {text-align:center !important;}

.tl {text-align:left !important;}
.tr {text-align:right !important;}
.tc {text-align:center !important;}

.td_tl td, .td_tl {padding-left:5px !important;padding-right:5px !important;text-align:left !important;}
.td_tr td, .td_tr {padding-left:5px !important;padding-right:5px !important;text-align:right !important;}
.td_tc td, .td_tc {text-align:center !important;}

/* border */
.bor_red {border:1px solid red;}
.noline {border:none !important}
.noline_t {border-top:0px !important;}
.noline_r {border-right:0px !important;}
.noline_b {border-bottom:0px !important;}
.noline_l {border-left:0px !important;}

/* display */
.dp_i {display:inline !important;}
.dp_ib {display:inline-block !important;}
.dp_b {display:block !important;}

/* width */
.wa {width:auto !important;}
.w100p {width:100% !important;}
.w30 {width:30px !important;}
.w35 {width:35px !important;}
.w40 {width:40px !important;}
.w50 {width:50px !important;}
.w60 {width:60px !important;}
.w70 {width:70px !important;}
.w80 {width:80px !important;}
.w90 {width:90px !important;}
.w100 {width:100px !important;}
.w110 {width:110px !important;}
.w120 {width:120px !important;}
.w130 {width:130px !important;}
.w140 {width:140px !important;}
.w150 {width:150px !important;}
.w160 {width:160px !important;}
.w180 {width:180px !important;}
.w200 {width:200px !important;}
.w250 {width:250px !important;}
.w300 {width:300px !important;}
.w350 {width:350px !important;}
.w400 {width:400px !important;}
.w450 {width:450px !important;}
.w500 {width:500px !important;}
.w550 {width:550px !important;}
.w600 {width:600px !important;}
.w650 {width:650px !important;}

/* height */
.h30 {height:30px !important;}
.h35 {height:35px !important;}
.h40 {height:40px !important;}
.h50 {height:50px !important;}
.h55 {height:55px !important;}
.h60 {height:60px !important;}
.h70 {height:70px !important;}
.h80 {height:80px !important;}
.h90 {height:90px !important;}
.h100 {height:100px !important;}
.h110 {height:110px !important;}
.h120 {height:120px !important;}
.h130 {height:130px !important;}
.h140 {height:140px !important;}
.h150 {height:150px !important;}
.h160 {height:160px !important;}
.h180 {height:180px !important;}
.h200 {height:200px !important;}
.h250 {height:250px !important;}
.h300 {height:300px !important;}
.h350 {height:350px !important;}
.h400 {height:400px !important;}
.h450 {height:450px !important;}
.h500 {height:500px !important;}
.h550 {height:550px !important;}
.h600 {height:600px !important;}
.h650 {height:650px !important;}

/* margin */
.mg0 {margin:0 !important;}

.mt0 {margin-top:0 !important;}
.mt3 {margin-top:3px !important;}
.mt5 {margin-top:5px !important;}
.mt8 {margin-top:8px !important;}
.mt10 {margin-top:10px !important;}
.mt13 {margin-top:13px !important;}
.mt15 {margin-top:15px !important;}
.mt20 {margin-top:20px !important;}
.mt23 {margin-top:23px !important;}
.mt25 {margin-top:25px !important;}
.mt30 {margin-top:30px !important;}
.mt35 {margin-top:35px !important;}
.mt40 {margin-top:40px !important;}
.mt45 {margin-top:45px !important;}
.mt50 {margin-top:50px !important;}
.mt60 {margin-top:60px !important;}

.mb0 {margin-bottom:0 !important;}
.mb3 {margin-bottom:3px !important;}
.mb5 {margin-bottom:5px !important;}
.mb8 {margin-bottom:8px !important;}
.mb10 {margin-bottom:10px !important;}
.mb13 {margin-bottom:13px !important;}
.mb15 {margin-bottom:15px !important;}
.mb20 {margin-bottom:20px !important;}
.mb23 {margin-bottom:23px !important;}
.mb25 {margin-bottom:25px !important;}
.mb30 {margin-bottom:30px !important;}
.mb35 {margin-bottom:35px !important;}
.mb40 {margin-bottom:40px !important;}
.mb45 {margin-bottom:45px !important;}
.mb50 {margin-bottom:50px !important;}
.mb60 {margin-bottom:60px !important;}

.mr0 {margin-right:0 !important;}
.mr3 {margin-right:3px !important;}
.mr5 {margin-right:5px !important;}
.mr8 {margin-right:8px !important;}
.mr10 {margin-right:10px !important;}
.mr13 {margin-right:13px !important;}
.mr15 {margin-right:15px !important;}
.mr20 {margin-right:20px !important;}
.mr23 {margin-right:23px !important;}
.mr25 {margin-right:25px !important;}
.mr30 {margin-right:30px !important;}
.mr35 {margin-right:35px !important;}
.mr40 {margin-right:40px !important;}
.mr45 {margin-right:45px !important;}
.mr50 {margin-right:50px !important;}
.mr60 {margin-right:60px !important;}
.mr70 {margin-right:70px !important;}
.mr100 {margin-right:100px !important;}

.ml0 {margin-left:0 !important;}
.ml3 {margin-left:3px !important;}
.ml5 {margin-left:5px !important;}
.ml8 {margin-left:8px !important;}
.ml10 {margin-left:10px !important;}
.ml13 {margin-left:13px !important;}
.ml15 {margin-left:15px !important;}
.ml20 {margin-left:20px !important;}
.ml23 {margin-left:23px !important;}
.ml25 {margin-left:25px !important;}
.ml30 {margin-left:30px !important;}
.ml35 {margin-left:35px !important;}
.ml40 {margin-left:40px !important;}
.ml45 {margin-left:45px !important;}
.ml50 {margin-left:50px !important;}
.ml60 {margin-left:60px !important;}

/* padding */
.pd0 {padding:0 !important;}

.pt0 {padding-top:0 !important;}
.pt3 {padding-top:3px !important;}
.pt5 {padding-top:5px !important;}
.pt8 {padding-top:8px !important;}
.pt10 {padding-top:10px !important;}
.pt13 {padding-top:13px !important;}
.pt15 {padding-top:15px !important;}
.pt20 {padding-top:20px !important;}
.pt23 {padding-top:23px !important;}
.pt25 {padding-top:25px !important;}
.pt30 {padding-top:30px !important;}
.pt35 {padding-top:35px !important;}
.pt40 {padding-top:40px !important;}
.pt45 {padding-top:45px !important;}
.pt50 {padding-top:50px !important;}
.pt60 {padding-top:60px !important;}

.pb0 {padding-bottom:0 !important;}
.pb3 {padding-bottom:3px !important;}
.pb5 {padding-bottom:5px !important;}
.pb8 {padding-bottom:8px !important;}
.pb10 {padding-bottom:10px !important;}
.pb13 {padding-bottom:13px !important;}
.pb15 {padding-bottom:15px !important;}
.pb20 {padding-bottom:20px !important;}
.pb23 {padding-bottom:23px !important;}
.pb25 {padding-bottom:25px !important;}
.pb30 {padding-bottom:30px !important;}
.pb35 {padding-bottom:35px !important;}
.pb40 {padding-bottom:40px !important;}
.pb45 {padding-bottom:45px !important;}
.pb50 {padding-bottom:50px !important;}
.pb60 {padding-bottom:60px !important;}
.pb70 {padding-bottom:70px !important;}
.pb80 {padding-bottom:80px !important;}

.pr0 {padding-right:0 !important;}
.pr3 {padding-right:3px !important;}
.pr5 {padding-right:5px !important;}
.pr8 {padding-right:8px !important;}
.pr10 {padding-right:10px !important;}
.pr13 {padding-right:13px !important;}
.pr15 {padding-right:15px !important;}
.pr20 {padding-right:20px !important;}
.pr23 {padding-right:23px !important;}
.pr25 {padding-right:25px !important;}
.pr30 {padding-right:30px !important;}
.pr35 {padding-right:35px !important;}
.pr40 {padding-right:40px !important;}
.pr45 {padding-right:45px !important;}
.pr50 {padding-right:50px !important;}
.pr60 {padding-right:60px !important;}

.pl0 {padding-left:0 !important;}
.pl3 {padding-left:3px !important;}
.pl5 {padding-left:5px !important;}
.pl8 {padding-left:8px !important;}
.pl10 {padding-left:10px !important;}
.pl13 {padding-left:13px !important;}
.pl15 {padding-left:15px !important;}
.pl20 {padding-left:20px !important;}
.pl23 {padding-left:23px !important;}
.pl25 {padding-left:25px !important;}
.pl30 {padding-left:30px !important;}
.pl35 {padding-left:35px !important;}
.pl40 {padding-left:40px !important;}
.pl45 {padding-left:45px !important;}
.pl50 {padding-left:50px !important;}
.pl60 {padding-left:60px !important;}

.en16{ font-size:16px;font-family: "Myriad Pro",}
.en18{ font-size:18px;font-family: "Myriad Pro",}
.en20{ font-size:20px;font-family: "Myriad Pro",}
.en22{ font-size:22px;font-family: "Myriad Pro",}
.en24{ font-size:24px;font-family: "Myriad Pro",}
.en30{ font-size:30px;font-family: "Myriad Pro",}
.en32{ font-size:32px;font-family: "Myriad Pro",}
.en34{ font-size:34px;font-family: "Myriad Pro",}
.kr14{ font-size:14px;font-family:맑은고딕, "Malgun Gothic" }
.kr16{ font-size:16px;font-family:맑은고딕, "Malgun Gothic" }
.kr18{ font-size:18px;font-family:맑은고딕, "Malgun Gothic" }
.kr20{ font-size:20px;font-family:맑은고딕, "Malgun Gothic" }
.kr24{ font-size:24px;font-family:맑은고딕, "Malgun Gothic" }
.bold{ font-weight:bold;}

