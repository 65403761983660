/* body {margin:0; padding:0; font-size: 14px; font-family: "Myriad Pro","Myriad Web","Tahoma","Helvetica","Arial",sans-serif;min-width: 1000px; }
img, table {border: 0; border-image-width:0}
html, body {overflow: hidden;} */


table.body {
  width: 100%;
  height: 100%;
  border: 0;
  margin: -2px
}

li{ list-style:none;}



/* body {
  min-width: 1000px
} */

.navi {
  color: #fff;
  height: 40px;
  line-height: 38px;
  background: #15455e;
  padding-left:7px;  
  font-size:18px;
}
/* padding-left:100px; */

.navi .part {
  padding-left:3px;
  padding-right:10px;
  height: 40px;
  text-align: center;
  float: left
}

.navi .btn_zoom {
  float: right;
  width: 42px;
  height: 35px;
  padding-right: 5px
}

.navi .remain {
  float: right;
  width:300px;
  height: 40px;
  text-align: right;
  color: #fff;
  padding-right:18px;
}

.navi b {
  font-weight: 900
}

/* .body {
  position: relative;
} */

.frame_box {padding-right:189px; padding-left:100px;}

.navi_panel {
  width: 101px;
  background: url(../../images/question/navi_bg.jpg) right repeat-y;
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0px;
  min-height: 1000px;
  border-right: 1px solid #adaeb3;
  font-size:18px;
  text-align:center;
  color:#fff;
  line-height:40px;

}
.navi_panel .navi_part{
	background:#5f8599;
	height:40px;
}

.navi_panel .navi_task{
	background:#f5a700;
	margin:10px 5px 5px 5px;
	height:30px;
  line-height: 30px;

}

.navi_panel ul.navi_num{
	margin:5px 6px 10px 6px;
	overflow:hidden;
	display:block;

}

.navi_panel ul.navi_num li{
	float:left;
	width:43px;
	height:28px;
	list-style:none;
	margin-right:1px;
	margin-bottom:1px; 
	line-height: 25px;
	background:#fff;
	color:#000;

}
.navi_panel ul.navi_num li.finish{
	background:#00aeeb;
	color:#fff;

}


.control_panel {
  width: 188px;
  background: url(../../images/question/panel_bg.jpg) right repeat-y;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 40px;
  min-height: 1000px;
  border-left: 1px solid #adaeb3
}


.quiz_contents { position:relative;
  padding: 5px 15px 35px 5px; min-height: 800px
}

.quiz_contents .quiz_text_box {
	border-style : outset;
	border-width:5px;
	padding:10px;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	background:#ddf2ff;
  
}

p
{
	margin:0px;
	line-height: 166%;
}

.quiz_contents .quiz_img_box {
  text-align: center;
  margin-top: 30px;
  max-width:1000px;
  margin: 0 auto;
}

.headphone_img { text-align: center; margin-top: 150px}

.quiz_contents .quiz_img_box img {
  display: block;
}

img {
  border: 0;
  margin: 0;
  padding: 0
}

.clear {
  clear: both
}

.volume {
  width: 188px;
  height: 126px;
  border-bottom: 1px solid #b5bdc7;
  position: relative;
  background: url(../../images/question/volume_bg.jpg)
}

.line .title {
  padding-bottom: 6px
}

.line {
  border-top: 1px solid #fff;
  border-bottom: 1PX solid #b5bdc7;
  padding: 20px 12px
}

.progress_box {
  background: url(../../images/question/progress_bg.gif);
  width: 119px;
  height: 13px;
  float: left;
  margin-top:5px;
}

.progress {
  background: url(../../images/question/progress.gif);
  width: 119px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: 0
}

.progress_cover {
  background: url(../../images/question/progress_cover.gif);
  width: 119px;
  height: 13px
}
/*
.volume_cover {
  background: url(../../images/question/vovolume_cover.gif);
  width: 119px;
  height: 23px
}
*/


.time_box {
  background: url(../../images/question/time_bg.jpg);
  width: 39px;
  height: 23px;
  text-align: center;
  line-height: 23px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  float: right
}

.control_panel .disabled {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  zoom: 1;
  opacity: 0.5
}

.control_panel .hide1 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  zoom: 1;
  opacity: 0
}

.studying_view{
	background:#b1f0a3;
	border:#26a20a 1px solid;}
	
.listening_view{
	background:#afe9ff;
	border:#0f6789 1px solid;}
	
.response_view{
	background:#ffdeb9;
	border:#e7860e 1px solid;}
	
.msg_warning{ text-align:center; margin:10px auto;}
	
	
.control_panel .disabled .progress,
.control_panel .disabled .time_box span {
  display: none
}

.control_panel .quiz_btn_box {
	text-align:center;
  margin: 10px 0px 0 0px;
}

.quiz_btn_box {
  cursor: pointer;
  cursor: hand
}

.bottom_line {
  border-bottom: 1px solid #fff;
  margin: 20px -12px -22px -12px
}

.recoding span {
  display: none
}


.recoding .active {
  position: relative;  height: 37px;
}

  .recoding .active img {     display: block;
    position: absolute;
    left: 12px;
    top: 5px;
  }
  .recoding .active .text {
    color:#fff;
    font-size: 18px;
    font-weight: 500;
    height: 37px; line-height: 36px  ;text-align: left;
;padding-left: 50px
}
.disabled .recoding .active {
  display: none
}
.disabled .recoding span {
  display: block;
  font-size: 18px;
  font-weight: 500;
  height: 37px;
  line-height: 36px;
  color: #fff;
  text-align: left;
  padding-left: 50px
}

.recoding_bg .alert {
  display: block;
  font-size: 12px;
  color: #fff100;
  text-align: center; padding-top:3px
}

.disabled .recoding_bg .alert {
  display: none
}

.quiz_btn_box img {
  display: block
}

.recoding_bg {
  background: url(../../images/question/recoding_bg.jpg);
  width: 164px;
  height: 59px;
  margin: 10px auto;
}

.emergency {
  position: absolute;
  left: 1092px;
  right: 0;
  bottom: 48px;
  width: 100px;
  background: url(../../images/question/panel_bg.jpg) right repeat-y;
  text-align: right;
  height: auto;
  padding: 0 0 10px 0;z-index: 2
}

.emergency .em_line {
  width: 192px;
  border-top: 1px solid 1PX solid #b5bdc7;
  border-bottom: 1px solid #fff;
  height: 0px;
  margin-bottom: 10px
}

.emergency .btn_box img {
  margin: auto
}

.volume_btn {
  position: absolute;
  right: 5px;
  top: 8px;
  cursor: pointer;
  cursor: hand
}

.volume .equalizer {
  background: #646464;
  position: absolute;
  width: 151px;
  height: 48px;
  right: 19px;
  top: 61px;
}

.volume .volume_bar {
  background: url(../../images/question/volume_control_bg.gif);
  width: 58px;
  height: 126px;
  position: absolute;
  z-index: 3;
  left: 82px;
  top: -10px;
  display: none
}

.volume .active {
  display: block
}

.volume .volume_bar .progress_box {
  margin: 8px 0 0 12px;
}

.volume .volume_cursor {
  position: absolute;
  left: 12px;
  bottom: 13px;
  width: 119px;
}

.volume .volume_cursor img {
  position: absolute;
      bottom: 100px;
      margin-bottom: -5px;
}

 .queston_Frame { width:100%; height:100%; } /*min-height: 942px */


.descript_box{ position:relative;
	line-height:150%;
	min-height:5px !important;
	border-style : outset;
	border-width:5px;
	padding:10px;
	font-size: 18px;
	line-height: 30px;
	background:#ddf2ff;
	margin-bottom:120px;
}

.descript_box .hide{ 
  position:relative;
	line-height:150%;
	min-height:5px !important;
	border-style : outset;
	border-width:5px;
	padding:10px;
	font-size: 18px;
	line-height: 30px;
	background:#ddf2ff;
  margin-bottom:120px;
  visibility: hidden;
}
.qust_box{position:relative; left:50%; margin-left:-390px;width:720px;}


.headset_box { float:left; width:330px; height:330px; margin-right:50px; border:#3e5360 2px solid;}
.mic_box {float:left; width:330px; height:330px;border:#3e5360 2px solid;}
.qust_box .box_top {height:275px;}
.qust_box .box_bottom {position:relative;height:55px; line-height:400%; background:#3e5360; }
.qust_box .headset_box  p{ display:block;height:55px; width:100%; background:url(../../images/question/msg_say.gif) #3e5360 no-repeat 50%;}
.qust_box .box_bottom span{display: block; width:35px; height:35px;position: absolute; left: 35px;top: 14px;background:url(../../images/question/recoding.gif) #3e5360 no-repeat}

.headset{ background:url(../../images/question/img_headset.png) no-repeat 50% 50%;}
.mic{background:url(../../images/question/img_mic.png) no-repeat 50% 50%;}

.progress_box2 {
	position:absolute;
	margin-left:80px;
  background: url(./../../images/question/progress_bg2.gif);
  width: 200px;
  height: 13px;
  margin-top:22px;
}

.progress2 {
  background: url(../../images/question/progress2.gif);
  width: 200px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: 0
}

.progress_cover2 {
  background: url(../../images/question/progress_cover2.png);
  width: 200px;
  height: 13px
}


.qust_box .disabled, 
.qust_box .disabled .box_bottom  {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  zoom: 1;
  opacity: 0.5
}

.qust_box .disabled .box_bottom p{background:url(../../images/question/msg_say_dis.png) #3e5360 no-repeat 50%;}
.qust_box .disabled .box_bottom .img{display: block; width:35px; height:35px;position: absolute;  left: 35px;top: 14px; background:url(../../images/question/recoding_dis.gif) #3e5360 no-repeat}


.headset_box .disabled {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  zoom: 1;
  opacity: 0.5
}
.headset_box .disabled p{background:url(../../images/question/msg_say_dis.png) #3e5360 no-repeat 50%;}

