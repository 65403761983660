/* @import "common.css"; */

/* body {margin:0;padding:0;font-size: 14px;font-family: "Myriad Pro", "Myriad Web", "Tahoma", "Helvetica", "Arial", sans-serif;min-width: 1000px;} */
img, table {border: 0;border-image-width:0}
/* html, body {overflow: hidden;} */
table.body {width: 100%;height: 100%;border: 0;margin: -2px}
/* body {min-width: 1000px} */
li{list-style:none;}
.navi {color: #fff;height:35px; font-size:18px;line-height:190%;background: #15455e;text-align:left; }
/* padding-left:20px; */
.navi b {font-weight: 900}
.navi2 {color: #fff;height:35px; font-size:18px;line-height:190%;background: #15455e;text-align:left;}
/* padding-left:20px; */

.body {position: relative;}
.contents_info {position:relative;padding-top:50px;min-height: 1000px;background:#ebebec;}
.info_title { padding-top:30px; padding-bottom:0px;}
.info_title dl{margin:10px auto;width:450px;overflow:hidden;font-size:34px;}
.info_title dt{float:left; clear:both;height:30px; width:160px; text-align:right;padding:5px;}
.info_title dd {color:#0177bd;}
.info_box { position:relative;margin:50px auto;background: url(../../images/sub/info_bg.png) no-repeat;width:850px;height:258px;padding:50px;}
.info_box .seat {position:absolute; top:110px; left:60px; width:220px;text-align:center; color:#405663; font-size:150px;}
.info_box .birth {position:absolute; top:60px; left:400px; width:440px;text-align:center; font-size:50px;}
.info_box .name {position:absolute; top:130px; left:400px; width:440px;text-align:center; font-size:100px;}


.volume {position:absolute; right:20px; top:20px; } /*background: url(../../images/sub/volume_bg.jpg)*/
.volume_btn {position: absolute;right: 5px;top: 8px;cursor: pointer;cursor: hand}
.volume .volume_bar {width: 51px;height: 134px;position: absolute;z-index: 3;right: 0px;top: 50px;display: none}
/*background: url(../../images/sub/volume_control_bg.gif);*/
.volume .active {display: block}
.volume .volume_cursor {position: absolute;left: 12px;bottom: 13px;width: 119px;}
.volume .volume_cursor img {position: absolute;bottom: 100px;margin-bottom: -5px;}

.contents_guide1 {position:relative;padding-top:10px;min-height:1000px;background:#ebebec;}
.contents_guide {position:relative;padding-top:100px;min-height:1000px;background:#ebebec;}
.text_box { position:relative; margin:50px auto 0 auto;background: url(../../images/sub/text_bg.png) no-repeat;width:950px;height:883px;padding:50px;color:#405663;}
.text_box .title{ position:absolute; left:0; top:25px; width:100%; font-size:30px; color:#405663; font-weight:bold; text-align:center;}
.text_box .title .right_btn{ position:absolute; right:30px;top:0px;}
.text_box .seat{position:absolute; top:150px; left:20px; width:280px; padding-top:80px; height:237px;background: url(../../images/sub/seat_bg.png) right no-repeat;text-align:center;  font-size:150px;}
.text_box .headset{position:absolute; top:130px; left:340px; width:550px; line-height:200%; height:400px;background: url(../../images/sub/headset.png) right 90% no-repeat; font-size:30px;}

.step{position:absolute; top:130px; left:80px; width:800px; height:400px; padding:0px;background: url(../../images/sub/step_bg.png) 100px 160px no-repeat; font-size:30px;}
.step ol {overflow:hidden;}
.step ol li{float:left; margin-right:70px;;}
.step ol li p{ margin-bottom:20px;}
.step ol li .btn{display:block; margin-top:110px; margin-left:40px;}

.user_info{position:absolute; top:120px; left:340px; width:460px;font-size:30px;}
.user_info dl{margin:30px auto 0px auto;width:410px;overflow:hidden;font-size:26px;}
.user_info dt{float:left; clear:both;height:46px; width:170px; font-size:20px; line-height:210%; font-weight:bold; color:#737373; background: url(../../images/sub/userinfo_bg.png) no-repeat;text-align:right; margin-bottom:10px;padding-right:15px;}
.user_info dd {font-size:22px;height:36px; line-height:180%; padding-left:20px; font-weight:bold;}

/* .notice {position:absolute; top:110px; left:60px; font-size:16px; line-height:130%;} */
.notice {top:110px; left:60px; font-size:16px; line-height:130%;margin-left:auto;margin-right:auto;display:block;width:50%}
.notice .box{display:block; float:left;width:400px; margin-right:20px;}
.notice .box p{display:block; font-weight:bold;}
.notice .box li{background: url(../../images/sub/dot_bg.png) 0px 10px  no-repeat; padding-left:10px;}
.notice .box li ul li{background: url(../../images/sub/dot2_bg.png) 0px 10px  no-repeat; padding-left:10px;}

.pro_info { padding-top:10px;}


.notice_kr {position:absolute;top:100px; left:55px; font-size:16px; letter-spacing:-0.06em; line-height:150%;}
.notice_kr .box{display:block;float:left;width:410px; margin-right:20px;}
.notice_kr .box p{display:block; font-weight:bold;}
.notice_kr .box li{background: url(../../images/sub/dot_bg.png) 0px 10px  no-repeat; padding-left:10px;}
.notice_kr .box li ul li{background: url(../../images/sub/dot2_bg.png) 0px 10px  no-repeat; padding-left:10px;}





.basic_type {position:absolute; top:110px; left:55px; width:850px;}


.vol_info{line-height:180%;}
.vol_info2{padding-left:60px; padding-top:5px; line-height:180%; background: url(../../images/sub/img_notice.png) 0px 5px no-repeat;}
.vol_set {position:relative;margin-top:20px;height:125px; width:833px}
.vol_set .volset_btn_m {position: absolute; left: 260px;top: 38px;cursor: pointer;cursor: hand}
.vol_set .volset_btn_p {position: absolute; left: 550px;top: 38px;cursor: pointer;cursor: hand}
.vol_set .volset_bar {width:211px;height: 35px;position: absolute;z-index: 3; left: 325px;top:50px;display: none;}
.vol_set .active {display: block}
.vol_set .volset_cursor {position: absolute;left:-5px;bottom:0px;width:211px;}
.vol_set .volset_cursor img {position: absolute;bottom:8px;margin-bottom: -5px;}
.vol_btn_area {margin:10px 0px; text-align:center; }
.vol_btn_area span {padding-right:10px;}
.vol_btn_area span img{ cursor:pointer;}
.vol_btn_area .second{ display:inline-block; width:200px; text-align:right; padding-right:20px;line-height:180%; font-size:25px; color:#0082c7; padding-right:30px;}

.type_table {width:100%;border-top:2px solid #555; line-height:150%;}
.type_table thead th {text-align:center;color:#fff; background:#455b68; border-bottom:#d6d6d6 1px solid; border-right:#d6d6d6 1px solid; height:50px;font-size:18px;}
.type_table thead th.last {border-right:none;}
.type_table tbody td {text-align:center; border-bottom:#d6d6d6 1px solid; padding:5px;border-right:#d6d6d6 1px solid; height:60px;font-size:16px;}
.type_table tbody td.left {text-align:left;}
.type_table tbody td.last {border-right:none;}

.preparation li{background: url(../../images/sub/img_check.png) 0px 8px  no-repeat; font-size:17px; padding-left:30px; line-height:170%; margin-bottom:15px;}
.preparation li p{ font-size:20px;}


.direction_box {position:relative; margin:0px auto;width:900px;color:#405663;}
.direction_box .title{position:relative;margin:0px auto; font-size:35px; color:#fff; font-weight:bold; line-height:250%; text-align:center; width:627px; height:94px;background: url(../../images/sub/Direction_bg.png) no-repeat; }
.direction_box .dir_txt{font-size:26px; color:#4f4f4f; line-height:140%; margin-top:30px;}
.direction_box .dir_info{ margin:70px auto 0 auto;width:764px; height:55px;background: url(../../images/sub/Direction_bg2.png) no-repeat; font-size:24px; color:#F00; text-align:center; line-height:220%; }



.message_box {position:relative; margin:0px auto;width:100%;color:#405663; }
.message_box .top{position:absolute; top:-30px; left:0;width:100%;font-weight:bold; text-align:center;}
.message_box .title{position:relative; padding-top:50px;} 
.message_box .title p{ margin-top:150px;width:100%; background:#fff;font-size:45px; color:#338abd; font-weight:bold; line-height:250%; height:115px; text-align:center;}
.message_box .cont{position:relative; margin:0px auto;width:700px; padding-top:70px;}
.message_box .cont li{background: url(../../images/sub/img_check.png) 0px 5px  no-repeat; font-size:20px; padding-left:25px; margin-bottom:15px; }


.message_btn {position:relative; margin:70px auto 0 auto;text-align:center;background: url(../../images/sub/demo_btn.png) no-repeat;width:457px;height:74px; }
.message_btn a{ display:block; font-size:26px; font-weight:bold;color:#fff; text-align:center;width:100%;height:74px; line-height:230%;}

.message_btn2 {margin:0px auto 0 auto;text-align:center;background: url(../../images/sub/demo_btn2.png) no-repeat;width:228px;height:50px; }
.message_btn2 a{ display:block; font-size:18px; font-weight:bold;color:#fff; text-align:center;width:228px;height:50px; line-height:160%;}

.demo {position:relative;padding-top:80px;min-height: 900px;background:#ebebec;}
.demo .title{  width:100%; font-size:40px; color:#405663; font-weight:bold; text-align:center;}
.demo .title span{ display:block; font-size:18px; margin-top:15px; font-weight:bold; color:#5a5a5a;}
.demo_box {position:relative; margin:40px auto;background: url(../../images/sub/demo_bg.png) no-repeat;width:850px;height:393px;padding:60px 50px 50px 50px;color:#405663; text-align:center;}
.demo_box p{position:relative; margin:0px auto;background: url(../../images/sub/demo_btn.png) no-repeat;width:457px;height:74px; margin-top:20px;}
.demo_box a{ display:block; font-size:26px; font-weight:bold;color:#fff; text-align:center;width:100%;height:74px; line-height:230%;}

.img_box {border: 1px solid #000;margin-top: 20px;max-width:900px}
.headphone_img {text-align: center;margin-top: 150px}
.img_box img {width: 100%;display: block;}
.second_only { position:absolute;top:760px; width:100%;font-size:25px; color:#0082c7; font-weight:bold; text-align:center;}
.btn_only { position:absolute;top:600px; width:100%;font-size:25px; color:#0082c7; font-weight:bold; text-align:center;}
/*
.btn_box { position:absolute;top:600px; left:50%; margin-left:-300px; font-size:25px; color:#0082c7; font-weight:bold; text-align:center;}
.btn_box li{ float:left;}
.btn_box li.second{ width:350px; line-height:200%;}
.btn_box img{cursor: pointer;cursor: hand}
*/
 .btn_box1 { position:absolute;top:120px; left:80%; margin-left:-300px; font-size:25px; color:#0082c7; font-weight:bold; text-align:center;}
.btn_box1 li{ float:left;}
.btn_box1 li.second{ width:350px; line-height:200%;}
.btn_box1 img{cursor: pointer;cursor: hand}  

.btn_box2 { position:absolute;top:600px; left:50%; margin-left:-300px; font-size:25px; color:#0082c7; font-weight:bold; text-align:center;}
.btn_box2 li{ float:left;}
.btn_box2 li.second{ width:350px; line-height:200%;}
.btn_box2 img{cursor: pointer;cursor: hand}


img {border: 0;margin: 0;padding: 0} */
.clear {clear: both}

 * {box-sizing: border-box;}